// $(window).load(function() {
//     // Animate loader off screen
//     // $(".se-pre-con").fadeOut("slow");
// });

if (window.$)  $(".se-pre-con").fadeOut("slow");

$(document).ready(function() {
    $('div.contactWindow').hover(
        function() {
            $(this).animate({ right: '0' }, 300);
        },
        function() {
            $(this).animate({ right: '-179px' }, 300);
        }
    );
});

$(document).ready(function() {

    $navListWrapper = $('#header-navWrapper > ul');
    $providerListWrapper = $('#header-providers-wrapper');
    var tmp = window.langSet === 'en' ? ' Games' : '平台';

    for (var i = 0; i < providerList.length; i++) {
        switch (providerList[i].name) {
            case 'NYX':
                ;
            case 'PT':
                $navListWrapper.prepend($('<li>')./*addClass('mod-bkgd mod-bkgd-'+providerList[i].name).*/append($('<a>').attr('href', '/slotList/' + providerList[i].name).text(providerList[i].name + tmp)));
                break;

            case 'ALLBET': 
            case 'IBC': 
            case 'MW': 
                break;

            default:
                $providerListWrapper.prepend($('<li>')./*addClass('mod-bkgd mod-bkgd-'+providerList[i].name).*/append($('<a>').attr('href', '/slotList/' + providerList[i].name).text(providerList[i].name + (window.langSet=='en'?'':'平台'))));
        }
    }


    (function() {
        
        /*
          script for header START
        */
        var $tmp_loginForm = $('form#loginForm');
        if (!isLoggedIn && $tmp_loginForm.get(0) ) {

            $tmp_loginForm.ajax_form({
                successCallback: function(message) {
                    window.location.reload(true);
                },
                errorCallback: function(message) {
                    alert('uncaught error! See console for details')
                    console.error(message);
                },
                serverMsgs: {
                    'successResponse': 'success',
                    'errorResponse': [{
                        responseText: 'These credentials do not match our records.',
                        target: 'username',
                        message: '用户名或密码不正确'
                    }, {
                        responseText: 'These credentials do not match our records.',
                        target: 'password',
                        message: '用户名或密码不正确'
                    }, {
                        responseText: 'validation.captcha',
                        target: '_alert',
                        message: '验证码不正确'
                    }, {
                        responseText: 'The captcha field is required.',
                        target: '_alert',
                        message: '请填写验证码'
                    }]
                },
                showInvalid: function($target) {
                    $target.css('borderColor', 'red');
                },
                showValid: function($target) {
                    $target.css('borderColor', '#ffd452');
                },

            });


            console.log($('#loginForm input[name="username"]').val());

            $('.header-topbar-account-login').on('click', function(event) {
                $('.header-topbar-account-loginFormWrapper').slideDown("fast");
                $('.modal-background').addClass('state-loggingin');
            });

            $('.modal-background').on('click', function(event) {
                $('.modal-background').removeClass('state-loggingin');
                $('.header-topbar-account-loginFormWrapper').hide('fast');
            });

        } else if (isLoggedIn) {
            //unreadNumber
            $.ajax({
                url: '/member/unreadMsgCount',
                contentType: "application/json; charset=UTF-8",
                dataType: 'text',
                method: 'get',
                success: function(message) {
                    $('.unreadNumber').text(message);
                },
                error: function(message) {
                    // alert('error！！！!');
                    console.log(message);
                }
            })
        }
    })();



    if (isLoggedIn && $('a.header-topbar-account-logout').get(0)) {

        /* 为了登出 新生成一个form */
        $('a.header-topbar-account-logout').on('click', function() {

            var $tmp_postform = $('<form/>').attr({
                method: 'post',
                action: '/logout'
            });
            $('body').append($tmp_postform);
            $tmp_postform.html('<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '"/>');
            $tmp_postform.submit();
        });

        var tmp_balance = Number($('a.header-topbar-account-balance > span.text').text());
        $('a.header-topbar-account-balance > span.text').text('¥ ' + tmp_balance.toFixed(2));
    }


    $('.footer-medium-textSection ul.kode-form-list li p a').addClass('hvr-underline-reveal');
    $('.footer-medium div.partners > div').addClass('hvr-grow-rotate');

});
